import { uniq, uniqBy } from 'lodash';
import { DodFactSet, DodFilter, DodFilters, DodProductSubDimension, DodRunConfig } from '@/types/DodRun';
import {
    DodFilterType,
    DodProductFilterTypes,
    DodProductParentFilterTypes,
    DodTimePeriodFilterTypes,
} from '@/components/DodConfigEditor/types';
import { alert } from '@/components/form/ByzzerModal';

export function filterValuesToStrings(filter?: DodFilter<string[] | 'all'>, defaultValue: string[] = []): string[] {
    if (!filter) return defaultValue;

    const { values, summedSelections } = filter;
    if (values === 'all' || summedSelections.some((ss) => ss.values === 'all')) {
        return defaultValue;
    }

    return uniq([
        ...valuesToStrings(values),
        ...summedSelections.map((sum) => valuesToStrings(sum.values)).flat(), // returns an array of arrays
    ]);
}

export function isSelectedMarketNotAccessible(runconfig: DodRunConfig): boolean {
    const { values, summedSelections } = runconfig.filters.markets;
    const allValues = [...values, ...summedSelections.map((ss) => ss.values)].flat();
    const count = uniqBy(allValues, 'key').length;
    return count < 1;
}

export function alertSelectionLimitExceed(displayTabName: string, limit?: number) {
    return alert({
        content: `As a free user you can only select ${limit} ${displayTabName}. To see more, reach out to your account executive to upgrade your subscription.`,
    });
}

export function valuesToStrings(values: string[] | 'all', defaultValue: string[] = []): string[] {
    if (Array.isArray(values)) {
        return values;
    } else {
        return defaultValue;
    }
}

export function filterHasExplicitValues(filter: DodFilter<string[] | 'all'> | DodFilter): boolean {
    if (hasArrayValues(filter.values)) {
        return true;
    }
    if (filter.summedSelections.some((sum) => hasArrayValues(sum.values))) {
        return true;
    }

    return false;
}

export function filterHasValues(filter: DodFilter<string[] | 'all'> | DodFilter): boolean {
    if (
        (Array.isArray(filter.values) && filter.values.length) ||
        filter.values === 'all' ||
        filter.summedSelections.length
    ) {
        return true;
    }
    return false;
}

export function hasArrayValues(values: string[] | 'all'): boolean {
    return Boolean(Array.isArray(values) && values.length);
}

export function getKeyByValue(object, value) {
    return Object.keys(object).find((key) => object[key] === value);
}

export function isCharacteristicFilter(filterType: DodFilterType): boolean {
    return ['characteristics', 'customCharacteristics'].includes(filterType.type);
}

export function isPpgFilter(filterType: DodFilterType): boolean {
    return ['ppgs'].includes(filterType.type);
}

export function isMarketResetRequired(filterType: DodFilterType, value: DodFilters): boolean {
    // todo: add logic to determine if selected markets will be impacted
    return (
        filterType.type === 'categories' &&
        Boolean(value.markets.values.length || value.markets.summedSelections.length)
    );
}

export function isTimePeriodFilterTypes(filterType: string): boolean {
    return Object.values(DodTimePeriodFilterTypes).includes(filterType as DodTimePeriodFilterTypes);
}

export function isProductFilterTypes(filterType: string): boolean {
    return Object.values(DodProductFilterTypes).includes(filterType as DodProductFilterTypes);
}

export function isParentProductFilterTypes(filterType: string): boolean {
    return Object.values(DodProductParentFilterTypes).includes(filterType as DodProductParentFilterTypes);
}

export function isCharacteristic(type: string): boolean {
    return ['characteristics', 'customCharacteristics'].includes(type);
}

export const defaultProductOrder: DodProductSubDimension[] = [
    'departments',
    'superCategories',
    'categories',
    'subcategories',
    'parentCompanies',
    'manufacturers',
    'brands',
    'upcs',
    'productDescriptions',
];

export const DodFactSuffixes = {
    yearAgo: ` YA`,
    changeYearAgo: ` Change vs Year-Ago`,
    perChangeYearAgo: ` % Change vs Year-Ago`,
    shareByCategory: ` % Share By Category`,
    shareByCategoryYearAgo: ` % Share By Category Year-Ago`,
    shareByBrand: ` % Share By Brand`,
    shareByBrandYearAgo: ` % Share By Brand Year-Ago`,
    shareByProductSelections: ` % Share By Product Selections`,
    shareByProductSelectionsYearAgo: ` % Share By Product Selections Year-Ago`,
};

export function factSetToDisplayNames(factSet: DodFactSet): string[] {
    return [...factSetToCoreDisplayNames(factSet), ...factSetToSharedDisplayNames(factSet)];
}

export function factSetToCoreDisplayNames(factSet: DodFactSet): string[] {
    const { fact: currentPeriod, yearAgo, changeVsYearAgo, percentChangeVsYearAgo } = factSet;

    const values: string[] = [];
    if (currentPeriod) {
        values.push(factSet.display);
    }
    if (yearAgo) {
        values.push(`${factSet.display}${DodFactSuffixes.yearAgo}`);
    }
    if (changeVsYearAgo) {
        values.push(`${factSet.display}${DodFactSuffixes.changeYearAgo}`);
    }
    if (percentChangeVsYearAgo) {
        values.push(`${factSet.display}${DodFactSuffixes.perChangeYearAgo}`);
    }

    return values;
}

export function factSetToSharedDisplayNames(factSet: DodFactSet): string[] {
    const { category, categoryYearAgo, brand, brandYearAgo, productSelections, productSelectionsYearAgo } = factSet;
    const values: string[] = [];

    if (category) {
        values.push(`${factSet.display}${DodFactSuffixes.shareByCategory}`);
    }
    if (categoryYearAgo) {
        values.push(`${factSet.display}${DodFactSuffixes.shareByCategoryYearAgo}`);
    }
    if (brand) {
        values.push(`${factSet.display}${DodFactSuffixes.shareByBrand}`);
    }
    if (brandYearAgo) {
        values.push(`${factSet.display}${DodFactSuffixes.shareByBrandYearAgo}`);
    }
    if (productSelections) {
        values.push(`${factSet.display}${DodFactSuffixes.shareByProductSelections}`);
    }
    if (productSelectionsYearAgo) {
        values.push(`${factSet.display}${DodFactSuffixes.shareByProductSelectionsYearAgo}`);
    }

    return values;
}

export const DodRunMethod = {
    create: 'new run',
    edit: 'rerun',
    modifiedRun: 'modified run',
    layout: 'apply new layout run',
    ScheduledRun: 'scheduled run',
};

export const compareObjects = (obj1, obj2, parentKey = '') => {
    try {
        let diffs: string[] = [];

        const keys1 = obj1 ? Object.keys(obj1) : [];
        const keys2 = obj2 ? Object.keys(obj2) : [];

        // Check keys that are only in obj1
        keys1.forEach((key) => {
            const fullKey = parentKey ? `${parentKey}.${key}` : key;

            if (!keys2.includes(key)) {
                // Only consider keys missing in obj2 if their value in obj1 is not an empty array or undefined
                if (Array.isArray(obj1[key]) && obj1[key].length === 0) {
                    return;
                }
                if (obj1[key] !== undefined) {
                    diffs.push(`Key '${fullKey}' is missing in the second configuration.`);
                }
            } else if (typeof obj1[key] === 'object' && obj1[key] !== null) {
                // Recursively compare objects
                diffs = diffs.concat(compareObjects(obj1[key], obj2[key], fullKey));
            } else if (obj1[key] !== obj2[key]) {
                // Consider undefined or empty arrays as equivalent to missing keys
                if (
                    (Array.isArray(obj1[key]) && obj1[key].length === 0 && obj2[key] === undefined) ||
                    (obj1[key] === undefined && Array.isArray(obj2[key]) && obj2[key].length === 0)
                ) {
                    return; // Treat as equal
                }
                diffs.push(`Value of '${fullKey}' differs: '${obj1[key]}' vs '${obj2[key]}'.`);
            }
        });

        // Check keys that are only in obj2
        keys2.forEach((key) => {
            const fullKey = parentKey ? `${parentKey}.${key}` : key;

            if (!keys1.includes(key)) {
                // Only consider keys missing in obj1 if their value in obj2 is not an empty array or undefined
                if (Array.isArray(obj2[key]) && obj2[key].length === 0) {
                    return;
                }
                if (obj2[key] !== undefined) {
                    diffs.push(`Key '${fullKey}' is missing in the first configuration.`);
                }
            }
        });

        return diffs;
    } catch (err) {
        console.error('Error comparing objects', err);
        throw err;
    }
};

export const marketFolderLabelMapping = {
    'Major Market': 'majorMarkets',
    Division: 'divisions',
    Stateline: 'stateLines',
    Region: 'regions',
    Channel: 'channels',
    Account: 'accounts',
};

export const invertedMarketFolderLabelMapping = Object.fromEntries(
    Object.entries(marketFolderLabelMapping).map(([key, value]) => [value, key])
);

const weeksSellingFactIds: number[] = [1031];
const cdiBdiFactIds: number[] = [1021, 1071, 1072, 1073, 1074, 1075];
export const isWorkInProgressFact = (selectedFacts: DodFactSet[]): { disabled: boolean; hoverText: string } => {
    return {
        disabled: selectedFacts.some((fact) => [...weeksSellingFactIds, ...cdiBdiFactIds].includes(fact.id)),
        hoverText:
            'This selection type is not compatible with one of your fact selections. If you wish to include this type of selection in your run, you will need to remove any CDI/BDI and Number of Weeks Selling facts that were included.',
    };
};
